html {
	height:100%;
	max-width: 100%;
  overflow-x: hidden;
}
body {
	font-family: $body-font-family;
	max-width: 100%;
  overflow-x: hidden;
}
main {
	@include media-breakpoint-up(lg) {
		margin-top: 95px;
	}
}
h1, h2, h3, h4, h5, h6 {
	font-family: $header-font-family;
	letter-spacing: -0.03rem;
	line-height: 1.2;
	span {
		display: block;
		font-style: italic;
	}
}
h1 {
	font-size: 2.2rem;
}
h2 {
	font-size: 2rem;
}
h3 {
	font-size: 1.5rem;
}
h4 {
	font-size: 1.2rem;
}
h5 {
	font-size: 1.1rem;
}
h6 {
	font-size: 0.9rem;
}
@include media-breakpoint-up(sm) {
	h1 {
		font-size: 2.2rem;
	}
	h2 {
		font-size: 1.8rem;
	}
	h3 {
		font-size: 1.55rem;
	}
	h4 {
		font-size: 1.3rem;
	}
	h5 {
		font-size: 1.15rem;
	}
	h6 {
		font-size: 1rem;
	}
}
@include media-breakpoint-up(md) {
	h1 {
		font-size: 3rem;
	}
	h2 {
		font-size: 2.5rem;
	}
	h3 {
		font-size: 2rem;
	}
	h4 {
		font-size: 1.75rem;
	}
	h5 {
		font-size: 1.5rem;
	}
	h6 {
		font-size: 1.23rem;
	}
}
a {
	color: $brand-link-color;
	transition: all .3s;

	&:hover {
		color: darken($brand-link-color, 10%);
		text-decoration: none;
	}
}
//Backgrounds
.bg-darker {
	background-color: $bg-darker;
	color: $bg-darker-text;
}
.bg-gray-light {
	background-color: $bg-gray-light;
}
.intro {
	background-color: #344428;
	color: #c2ddaf;
}
//Owl carousel overrides
.owl-nav {
	display: none;
}
.owl-dots {
	margin-top: 1.5rem;
}
.owl-stage {
	display: flex;
	flex-wrap: wrap;
	.owl-item	{
	    height: auto !important;
	    display: flex;
	}
}
.card-body {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
//Go to top
.cd-top {
  display: inline-block;
  height: 40px;
  width: 40px;
  position: fixed;
  bottom: 40px;
  right: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  /* image replacement properties */
  overflow: hidden;
  white-space: nowrap;
  background: rgba($brand-link-color, 0.8);
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity .3s 0s, visibility 0s .3s;
  -moz-transition: opacity .3s 0s, visibility 0s .3s;
  transition: opacity .3s 0s, visibility 0s .3s;
  z-index: 10;
  border-radius: 3px;
  color: white;
}
.cd-top.cd-is-visible:hover, .cd-top.cd-fade-out:hover, .no-touch .cd-top:hover {
  transition: all .3s;
  background: rgba($brand-link-color, 1);
  color: white;
}
.cd-top.cd-is-visible {
  /* the button becomes visible */
  visibility: visible;
  opacity: 1;
}
.cd-top.cd-fade-out {
  /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
  background: rgba($brand-link-color, 0.5);
}
.no-touch .cd-top:hover {
  background: rgba($brand-link-color, 1);
  opacity: 1;
}
@media only screen and (min-width: 768px) {
  .cd-top {
    right: 20px;
    bottom: 20px;
  }
}
@media only screen and (min-width: 1024px) {
  .cd-top {
    height: 60px;
    width: 60px;
    right: 30px;
    bottom: 30px;
  }
}
//Forms override
#cf7md-form .cf7md-item, #cf7md-form input, #cf7md-form label, #cf7md-form textarea, #cf7md-form p, #cf7md-form h1, #cf7md-form h2, #cf7md-form h3, #cf7md-form h4, #cf7md-form h5, #cf7md-form h6, #cf7md-form.cf7md-form + .wpcf7-response-output {
	font-family: $body-font-family;
}
#cf7md-form .mdc-textfield--focused .mdc-textfield__label {
	color: $brand-link-color;
}
#cf7md-form .mdc-select:focus {
  border-bottom-color: $brand-link-color;
}
#cf7md-form .mdc-textfield--multiline .mdc-textfield__input:focus,
#cf7md-form .mdc-textfield:not(.mdc-textfield--upgraded) .mdc-textfield__input:focus,
#cf7md-form .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
#cf7md-form .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
#cf7md-form .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__outer-circle {
  border-color: $brand-link-color;
}
#cf7md-form .mdc-textfield--focused.mdc-textfield--upgraded:not(.mdc-textfield--fullwidth):not(.mdc-textfield--multiline)::after,
#cf7md-form .mdc-multi-select:focus .mdc-list-item:checked,
#cf7md-form .mdc-checkbox__background::before,
#cf7md-form .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
#cf7md-form .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background,
#cf7md-form .mdc-radio__background::before,
#cf7md-form .mdc-radio__native-control:checked + .mdc-radio__background .mdc-radio__inner-circle,
#cf7md-form .mdc-button--primary.mdc-button--raised,
#cf7md-form .mdc-switch__native-control:checked ~ .mdc-switch__background::before,
#cf7md-form .mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob,
#cf7md-form .mdc-switch__native-control:checked ~ .mdc-switch__background .mdc-switch__knob::before {
  background-color: $brand-link-color;
}


