.btn {
	border: none;
	border-radius: 3px;
	font-size: 0.95rem;
	padding: .8rem 1.5rem;
	line-height: 1.3;
}
.btn-primary {
	background: $brand-link-color;
	color: white!important;
	&:hover {
		background: darken($brand-link-color, 5%);
	}
	i {
		font-size: 1rem;
	}
}