// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//Fonts
$header-font-family: 	'Libre Baskerville', serif;
$body-font-family: 		'Open Sans', sans-serif;

//Colors
$bg-dark: 				#343a40;
$bg-darker:    			#1b1f23;
$bg-darker-text:		#eaeaea;
$bg-gray-light:			#f4f4f4;
$brand-primary:			#c2ddaf;
$brand-link-color:		rgb(118, 145, 100);