.work {
	.list-group-item {
		padding-left: 0;
	}
	.card-img-top {
		height: 280px;
		object-fit: cover;
	}
}
.middle-section {
	background-size: cover;
	background-position: center center;
	h2 {
		text-shadow: 1px 1px 3px rgba(11,11,11,0.5);
	}
}
.kontrollklasser {
	img.sisa {
		max-width: 420px;
	}
}
.inforuta {
	.info-content {
		background-color: white;
		box-shadow: 0 0 35px 2px rgba(11,11,11,0.2);
		border-radius: .25rem;
	}
}
.single-aktivitet {
	.detaljer {
		ul {
			margin-left: 0;
			svg {
				margin-right: 10px;
				color: #8f6491;
			}
		}
		.detaljer-container {
			background-color: #432244;
			border-radius: 3px;
			color: white;
			hr {
				border-color: #8f6491;
			}
		}
	}
}

.aktivitet {
	.list-group-item {
		padding-left: 0;
	}
	.owl-carousel .owl-item img {
		height: 200px;
	    object-fit: cover;
	    @include media-breakpoint-up(md) {
	    	height: 300px;
	    }
	    @include media-breakpoint-up(lg) {
	    	height: 430px;
	    }
	}   
}
.kontakt {
	.intro {
		h4 {
			line-height: 1;
			margin-bottom: 0;
			@include media-breakpoint-down(md) {
				font-size: 1.2em;
			}
			@include media-breakpoint-down(sm) {
				font-size: 0.8em;
			}
		}
		.fa-ul {
			@include media-breakpoint-down(sm) {
				margin-left: 1em;
			}
		}
	}
	.list-inline {
		margin-bottom: 0;
		.fa-li {
			width: 1em;
			left: -1.3em;
			@include media-breakpoint-down(sm) {
				left: -1.3em;
			}
		}
		.list-inline-item:not(:last-child) {
			margin-right: 3rem;
			@include media-breakpoint-down(sm) {
				margin-right: 1.3rem;
			}
		}
	}
}
.bg-image {
	position: relative;
	background-size: cover;
	background-position: center center;
	color: white;
	h2, h5, p {
		text-shadow: 1px 1px 2px rgba(79,18,5,0.5);
	}
	&:before {
		content: '';
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		display: block;
		opacity: 0.65;
		background-color: rgba(33,40,27,.8);
	}
	.card-group {
		@media (max-width: 768px) {
			flex-direction: column;
			.card {
				border: none!important;
				flex: none;
			}
		}
	}
	.card {
		background: transparent;
		border: none;
		padding: 1.25rem;
		img {
			border-radius: 5px;
			box-shadow: 0 0 20px 0 rgba(29,28,6,0.6);
		}
	}
}
.photo-box {
	margin: 0 0 3px 0;

	.image-wrap {
		img {
			width: 100%;
		}

		.likes {
			position: absolute;
			bottom: 5px;
			left: 15px;
			padding: 10px;
			background: black;
			background: rgba(11,11,11, 0.4);
			color: $white;
		}
	}
	.description {
		font-size: 12px;
		background: $white;
		letter-spacing: 1px;
		height: 75px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
}
.related-posts {
	background-color: $bg-gray-light;
	border-radius: 3px;
	font-family: $header-font-family;
	line-height: 1.2;
	img {
		height: 100px;
	    object-fit: cover;
	    @include media-breakpoint-up(md) {
	    	height: 80px;
	    }
	    @include media-breakpoint-up(lg) {
	    	height: 80px;
	    }
	}
}
.gallery ul {
	display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    list-style: none;
    padding: 0;
    li {
	    a {
	    	border: 3px solid #000;
	    	box-shadow: 3px 3px 8px 0px rgba(0,0,0,0.3);
	    	display: block;
	    }
    }
}