footer {
	.fa-ul {
		margin-left: 1.3em;
	}
	.copyright {
		border-top: 1px solid rgba(255,255,255,0.1);
	}
}
.single-aktivitet {
	.contact > .container {
		border-top: 1px solid rgba(11,11,11,.1);
		padding-top: 3rem!important;
	}
}
.social-footer {
	background-color: #432244;
	h4 {
		color: white;
		line-height: 1;
		margin: 0;
	}
	.social-menu {
		font-size: 2rem;
	}
}
.bottom-menu {
	ul {
		padding: 0;
		li {
			list-style: none;
			list-style-type: none;
		}
	}
}
.breadcrumbs {
	background-color: #1b1f23;
	p {
		margin-bottom: 0;
		border-bottom: 1px solid rgba(255,255,255,0.1);
		color: rgba(234,234,234,1);
	}
}