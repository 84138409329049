header {
	background-color: rgba(52,68,40,1);
	width: 100%;
	top: 0;
	left: 0;
	right: 0;
	z-index: 200;
	display: flex;
	align-items: center;
	position: relative;
	padding: 7px 0;
  @include media-breakpoint-up(lg) {
  	padding: 0;
  	position: fixed;
  	background-color: rgba(52,68,40,1);
		&.headroom {
		  will-change: transform;
		  transition: all .5s linear;
		}
		&.headroom--top {
			transition: all .5s ease;
			height: 95px!important;
			.brand img {
		    	transition: all .5s ease;
		    	width: 80px;
		    }
		} 
		&.headroom--not-top {
		    transition: all .5s ease;
		    height: 65px;
		    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
				background: rgba(52,68,40,1);
		    .brand img {
		    	transition: all .5s ease;
		    	width: 55px;
		    }
		}
}
	nav {
		z-index: 200;
		font-family: $body-font-family;
		&.navbar {
			padding: 0;
			ul {
				>li {
					>a {
						font-size: 1rem;
						color: #c2ddaf;
					}
				}
			}
		}
	}
	.brand {
		img {
			width: 60px;
		}
	}
	.navbar-dark .navbar-nav .nav-link, .social-menu a {
		color: #c2ddaf;
		font-weight: 600;
		letter-spacing: -0.06rem;
		&:hover {
			color: darken(#c2ddaf, 10%);
		}
	}
	.social-menu {
		a {
			@include media-breakpoint-down(sm) {
				font-size: 1.9em;
				&:not(:last-child) {
					margin-right: 0.6em;
				}
			}
		}
	}
}
.hero-slider {
	width: 100%;
	height: 50vh;
	@include media-breakpoint-up(sm) {
		height: 65vh;
	}
	@include media-breakpoint-up(md) {
		height: 75vh;
	}
	.owl-dots {
		margin-top: -2rem;
		z-index: 200;
		position: relative;
	}
}
.hero {
	text-shadow: 2px 2px 7px rgba(11,11,11,0.3);
	width: 100%;
	height: 50vh;
	@include media-breakpoint-up(sm) {
		height: 65vh;
	}
	@include media-breakpoint-up(md) {
		height: 75vh;
	}
	&:before {
    content:"";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(11, 11, 11, .4);
    .single-aktivitet & {
    	background-color: transparent;
    }
	}

}
.single-aktivitet {
	header {
		@include media-breakpoint-up(lg) {
			background-color: rgba(52,68,40,1);
			position: fixed;
			&.headroom {
			  will-change: transform;
			  transition: all .5s linear;
			}
			&.headroom--top {
				transition: all .5s ease;
				height: 100px!important;
				.brand img {
			    	transition: all .5s ease;
			    	width: 90px;
			    }
			} 
			&.headroom--not-top {
			    transition: all .5s ease;
			    height: 80px;
			    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, 0.15);
					background: rgba(52,68,40,1);
			    .brand img {
			    	transition: all .5s ease;
			    	width: 60px;
			    }
			}
		}
	}
}
.social {
	opacity: .8;
	&:hover {
		opacity: 1;
	}
}

//Hamburger menu animation
.navbar-toggler {
	position: relative;
	border: none;
}
.navbar-toggler:focus,
.navbar-toggler:active {
    outline: 0;
}

.navbar-toggler span {
   display: block;
   background-color: white;
   height: 3px;
   width: 25px;
   margin-top: 4px;
   margin-bottom: 4px;
   -webkit-transform: rotate(0deg);
   -moz-transform: rotate(0deg);
   -o-transform: rotate(0deg);
   transform: rotate(0deg);
   position: relative;
   left: 0;
   opacity: 1;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
   -webkit-transition: transform .35s ease-in-out;
   -moz-transition: transform .35s ease-in-out;
   -o-transition: transform .35s ease-in-out;
   transition: transform .35s ease-in-out;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    opacity: 0.9;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
    height: 12px;
    visibility: hidden;
    background-color: transparent;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
    position: absolute;
    left: 12px;
    top: 10px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
    opacity: 0.9;
}